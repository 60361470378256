

































































































import { Vue, Component } from "vue-property-decorator";
import { formatterNumber, reverseFormatNumber } from "@/validator/globalvalidator";
const Props = Vue.extend({
  props: [
    "changeAmount",
    "loadingTable",
    "disabledTable",
    "onchangeFormMandatory"
  ],
  methods: {
    formatterNumber,
    reverseFormatNumber,
  }
});
@Component({
  components: {
    CSelectTermOfPayment: () => import(/*webpackPrefetch: true */"@/components/shared/select-term-of-payment/CSelectTermOfPayment.vue"),
  }
})
export default class TableDetails extends Props {
  formRules = {
    amount: {
      label: "lbl_amount",
      name: "amount",
      placeholder: "lbl_type_here",
      decorator: [
        "amount",
        {
          initialValue: 0,
          rules: [
            {
              required: true,
              // type: "number",
              message: (): string => this.$t("lbl_validation_required_error").toString(),
            },
          ],
        },
      ],
    },
    poNumber: {
      label: "lbl_po_number",
      name: "poNumber",
      placeholder: "lbl_type_here",
      decorator: [
        "poNumber",
        {
          initialValue: undefined,
          rules: [
            {
              required: false,
              message: (): string => this.$t("lbl_validation_required_error").toString(),
            },
          ],
        },
      ],
    },
    termOfPayment: {
      label: "lbl_term_of_payment",
      name: "termOfPayment",
      placeholder: "lbl_choose",
      decorator: [
        "termOfPayment",
        {
          initialValue: undefined,
          rules: [
            {
              required: true,
              message: (): string => this.$t("lbl_validation_required_error").toString(),
            },
          ],
        },
      ],
    },
    lineAccount: {
      label: "lbl_line_account",
      name: "lineAccount",
      placeholder: "lbl_insert",
      decorator: [
        "lineAccount",
        {
          initialValue: undefined,
          rules: [
            {
              required: true,
              message: (): string => this.$t("lbl_validation_required_error").toString(),
            },
          ],
        },
      ],
    },
  };
}
