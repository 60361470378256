var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-row",
    { attrs: { gutter: [16, 16] } },
    [
      _c(
        "a-col",
        { attrs: { span: 16 } },
        [
          _c(
            "a-form-item",
            { attrs: { label: _vm.$t(_vm.formRules.amount.label) } },
            [
              _c("a-input", {
                directives: [
                  {
                    name: "decorator",
                    rawName: "v-decorator",
                    value: [
                      "amount",
                      {
                        initialValue: 0,
                        rules: [
                          {
                            required: true,
                            message: _vm.$t("lbl_validation_required_error")
                          }
                        ]
                      }
                    ],
                    expression:
                      "\n          [\n            'amount',\n            {\n              initialValue: 0,\n              rules: [\n                {\n                  required: true,\n                  message: $t('lbl_validation_required_error'),\n                },\n              ],\n            },\n          ]\n        "
                  }
                ],
                staticStyle: { width: "100%" },
                attrs: {
                  name: _vm.formRules.amount.name,
                  min: 0,
                  placeholder: _vm.$t(_vm.formRules.amount.placeholder),
                  autocomplete: "off",
                  disabled: _vm.disabledTable.amount
                },
                on: {
                  blur: function(value) {
                    return _vm.changeAmount(value)
                  }
                }
              })
            ],
            1
          ),
          _c(
            "a-form-item",
            { attrs: { label: _vm.$t(_vm.formRules.poNumber.label) } },
            [
              _c("a-input", {
                directives: [
                  {
                    name: "decorator",
                    rawName: "v-decorator",
                    value: [
                      "poNumber",
                      {
                        initialValue: undefined,
                        rules: [
                          {
                            required: false,
                            message: _vm.$t("lbl_validation_required_error")
                          }
                        ]
                      }
                    ],
                    expression:
                      "\n          [\n            'poNumber',\n            {\n              initialValue: undefined,\n              rules: [\n                {\n                  required: false,\n                  message: $t('lbl_validation_required_error'),\n                },\n              ],\n            },\n          ]\n        "
                  }
                ],
                attrs: {
                  name: _vm.formRules.poNumber.name,
                  placeholder: _vm.$t(_vm.formRules.poNumber.placeholder),
                  autocomplete: "off",
                  disabled: _vm.disabledTable.amount
                }
              })
            ],
            1
          ),
          _c(
            "a-form-item",
            { attrs: { label: _vm.$t(_vm.formRules.termOfPayment.label) } },
            [
              _c("CSelectTermOfPayment", {
                directives: [
                  {
                    name: "decorator",
                    rawName: "v-decorator",
                    value: [
                      "termOfPayment",
                      {
                        initialValue: undefined,
                        rules: [
                          {
                            required: true,
                            message: _vm.$t("lbl_validation_required_error")
                          }
                        ]
                      }
                    ],
                    expression:
                      "\n          [\n            'termOfPayment',\n            {\n              initialValue: undefined,\n              rules: [\n                {\n                  required: true,\n                  message: $t('lbl_validation_required_error'),\n                },\n              ],\n            },\n          ]\n        "
                  }
                ],
                attrs: { disabled: _vm.disabledTable.termOfPayments },
                on: { change: _vm.onchangeFormMandatory }
              })
            ],
            1
          ),
          _c(
            "a-form-item",
            { attrs: { label: _vm.$t(_vm.formRules.lineAccount.label) } },
            [
              _c("a-input", {
                directives: [
                  {
                    name: "decorator",
                    rawName: "v-decorator",
                    value: [
                      "lineAccount",
                      {
                        initialValue: undefined,
                        rules: [
                          {
                            required: true,
                            message: _vm.$t("lbl_validation_required_error")
                          }
                        ]
                      }
                    ],
                    expression:
                      "\n          [\n            'lineAccount',\n            {\n              initialValue: undefined,\n              rules: [\n                {\n                  required: true,\n                  message: $t('lbl_validation_required_error'),\n                },\n              ],\n            },\n          ]\n        "
                  }
                ],
                attrs: {
                  name: _vm.formRules.lineAccount.name,
                  placeholder: _vm.$t(_vm.formRules.lineAccount.placeholder),
                  autocomplete: "off",
                  disabled: true
                },
                on: { change: _vm.onchangeFormMandatory }
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }